.asterisk {
    font-size: 1.6rem;
    vertical-align: top;
    color: $errorColorMain;
    @at-root #{&}-text {
        color: $fontColor2;
        vertical-align: top;
        font-size: 1.2rem;
    }
}

.formlabel {
    font-family: $fontFamily;
    font-size: 1.5rem;
    line-height: 1.736em;
    font-weight: $fontWeightRegular;
    letter-spacing: 0;
    padding: 0;
    position: relative;
    text-align: left;
    color: $fontColor2;
    &#{&}-disabled {
        color: $textDisabledColor;
    }
}
