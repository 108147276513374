$_grid: ".grid" !default;
#{$_grid} {
    box-sizing: border-box;
    &#{&}-container {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 16px);
        margin-left: -16px;
        margin-top: -16px;
        @for $i from 1 through length($gridSpacing) {
            &#{$_grid}-container-spacing-#{$i} > .grid {
                margin-top: 16px;
                padding-left: nth($gridSpacing, $i);
            }
        }
    }
    &#{&}-item {
        margin: 0;
    }
    &#{&}-zero-min-width {
        min-width: 0;
    }
    &#{&}-nowrap {
        flex-wrap: nowrap;
    }
    &#{&}-reverse {
        flex-wrap: wrap-reverse;
    }
    @media (min-width: 0) {
        @for $i from 1 through 12 {
            $percent: calc($i/12) * 100%;
            &#{&}-xs-#{$i} {
                flex-basis: $percent;
                max-width: $percent;
            }
        }
    }
    @media (min-width: 600px) {
        @for $i from 1 through 12 {
            $percent: calc($i/12) * 100%;
            &#{&}-sm-#{$i} {
                max-width: $percent;
                flex-basis: $percent;
            }
        }
    }
    @media (min-width: 900px) {
        @for $i from 1 through 12 {
            $percent: calc($i/12) * 100%;
            &#{&}-md-#{$i} {
                max-width: $percent;
                flex-basis: $percent;
            }
        }
    }
    @media (min-width: 1200px) {
        @for $i from 1 through 12 {
            $percent: calc($i/12) * 100%;
            &#{&}-lg-#{$i} {
                flex-basis: $percent;
                max-width: $percent;
            }
        }
    }
    @media (min-width: 1536px) {
        @for $i from 1 through 12 {
            $percent: calc($i/12) * 100%;
            &#{&}-xl-#{$i} {
                flex-basis: $percent;
                max-width: $percent;
            }
        }
    }
}