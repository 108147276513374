.input-adornment {
    display: flex;
    height: 0.01em; // Fix IE11 flexbox alignment. To remove at some point.
    max-height: 2em;
    align-items: center;
    white-space: nowrap;
    color: $actionActiveColor;
    &#{&}-position-start {
        margin-right: 8px;
    }
    &#{&}-position-end {
        margin-left: 8px;
    }
    &#{&}-disablePointerEvents {
        pointer-events: none;
    }
}
