.flex {
    display: flex !important;
}
.flex-inline {
    display: inline-flex !important;
}

.flex-direction-row {
    flex-direction: row;
}
.flex-direction-column {
    flex-direction: column;
}
.flex-direction-row-reverse {
    flex-direction: row-reverse;
}
.flex-direction-column-reverse {
    flex-direction: column-reverse;
}

.flex-x-center {
    align-content: center !important;
}
.flex-x-flex-end {
    align-content: flex-end !important;
}
.flex-x-flex-start {
    align-content: flex-start !important;
}
.flex-x-space-around {
    align-content: space-around !important;
}
.flex-x-space-between {
    align-content: space-between !important;
}
.flex-x-stretch {
    align-content: stretch !important;
}

.flex-y-flex-center {
    justify-content: center !important;
}
.flex-y-flex-start {
    justify-content: flex-start !important;
}
.flex-y-flex-end {
    justify-content: flex-end !important;
}
.flex-y-space-around {
    justify-content: space-around !important;
}
.flex-y-space-between {
    justify-content: space-between !important;
}
.flex-y-space-evenly {
    justify-content: space-evenly !important;
}

.flex-align-center {
    align-items: center !important;
}
.flex-align-flex-start {
    align-items: flex-start !important;
}
.flex-align-flex-end {
    align-items: flex-end !important;
}
.flex-align-stretch {
    align-items: stretch !important;
}
.flex-align-baseline {
    align-items: baseline !important;
}
.flex-align-self-center {
    align-self: center !important;
}

.align-self-start {
    align-self: flex-start !important;
}
.align-self-end {
    align-self: flex-end !important;
}
.align-self-center {
    align-self: center !important;
}
.align-self-stretch {
    align-self: stretch !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-gap {
    @each $i, $gapSapcing in $spacers {
        &-#{$i} {
            gap: $gapSapcing;
        }
    }
}

.flex-auto {
    flex: 1 1 auto !important;
}
