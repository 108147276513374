$_tooltip: '.tooltip';

#{$_tooltip}-popper {
    z-index: $tooltipZIndex;
    pointer-events: none;
    &#{$_tooltip}-disable-interactive {
        pointer-events: auto;
    }

    &[data-popper-placement*='bottom'] {
        #{$_tooltip}-arrow {
            top: 0;
            margin-top: -0.71em;
            &::before {
                transform-origin: 0 100%;
            }
        }
    }
    &[data-popper-placement*='top'] {
        #{$_tooltip}-arrow {
            bottom: 0;
            margin-bottom: -0.71em;
            &::before {
                transform-origin: 100% 0;
            }
        }
    }
    &[data-popper-placement*='right'] {
        #{$_tooltip}-arrow {
            left: 0;
            margin-left: -0.71em;
            height: 1em;
            width: 0.71em;
            &::before {
                transform-origin: 100% 100%;
            }
        }
        &#{$_tooltip}-isRtl #{$_tooltip}-arrow {
            right: 0;
            margin-right: -0.71em;
        }
    }
    &[data-popper-placement*='left'] {
        #{$_tooltip}-arrow {
            right: 0;
            margin-right: -0.71em;
            &::before {
                transform-origin: 0 0;
            }
        }
        &#{$_tooltip}-isRtl #{$_tooltip}-arrow {
            left: 0;
            margin-left: -0.71em;
        }
    }

    &[data-popper-placement*='left'] {
        #{$_tooltip} {
            transform-origin: right center;
            margin-right: 14px;
        }
        &#{$_tooltip}-touch #{$_tooltip} {
            margin-right: 24px;
        }

        &#{$_tooltip}-isRtl #{$_tooltip} {
            margin-left: 14px;
        }
        &#{$_tooltip}-isRtl#{$_tooltip}-touch #{$_tooltip} {
            margin-left: 24px;
        }
    }

    &[data-popper-placement*='right'] {
        #{$_tooltip} {
            transform-origin: left center;
            margin-left: 14px;
        }
        &#{$_tooltip}-touch #{$_tooltip} {
            margin-left: 24px;
        }
        &#{$_tooltip}-isRtl #{$_tooltip} {
            margin-right: 14px;
        }
        &#{$_tooltip}-isRtl#{$_tooltip}-touch #{$_tooltip} {
            margin-right: 24px;
        }
    }

    &[data-popper-placement*='top'] {
        #{$_tooltip} {
            transform-origin: center bottom;
            margin-bottom: 14px;
        }
        &#{$_tooltip}-touch #{$_tooltip} {
            margin-bottom: 24px;
        }
    }

    &[data-popper-placement*='bottom'] {
        #{$_tooltip} {
            transform-origin: center top;
            margin-top: 14px;
        }
        &#{$_tooltip}-touch #{$_tooltip} {
            margin-top: 24px;
        }
    }
}

#{$_tooltip} {
    background-color: rgba(gray(700), 0.95);
    border-radius: $shapeBorderRadius;
    color: #fff;
    font-family: $fontFamily;
    padding: 4px 8px;
    font-size: 1rem;
    max-width: 30rem;
    margin: 0.2rem;
    word-wrap: break-word;
    white-space: break-spaces;
    font-weight: $fontWeightMedium;
    &#{$_tooltip}-isArrow {
        position: relative;
        margin: 0;
    }
    &#{$_tooltip}-touch {
        padding: 8px 16px;
        font-size: 1.4rem;
        line-height: calc(16 / 14) + em;
        font-weight: $fontWeightRegular;
    }
}

#{$_tooltip}-arrow {
    overflow: hidden;
    position: absolute;
    width: 1em;
    height: 0.71em; /* = width / sqrt(2) = (length of the hypotenuse) */
    box-sizing: border-box;
    color: rgba(gray(700), 0.9);
    &::before {
        content: '';
        margin: auto;
        display: block;
        width: 100%;
        height: 100%;
        background-color: currentColor;
        transform: rotate(45deg);
    }
}
