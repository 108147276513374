.formhelpertext {
    @include mxVariants('body2');
    color: $textSecondaryColor;
    text-align: left;
    margin: 0.6rem 0 0 0;
    &#{&}-disabled {
        color: $textDisabledColor;
    }
    &#{&}-error {
        color: $errorColorMain;
    }
    &#{&}-size-small {
        margin-top: 0.6rem;
    }
    &#{&}-contained {
        margin-left: 1.4rem;
        margin-right: 1.4rem;
    }
}
