$_chip: ".chip";

#{$_chip}-root {
    max-width: 100%;
    font-family: $fontFamily;
    font-size: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    color: $textPrimaryColor;
    background-color: $textSecondaryColor;
    border-radius: 1.6rem;
    white-space: nowrap;
    transition: getTransition(background-color), getTransition(box-shadow);
    // label will inherit this from root, then `clickable` class overrides this for both
    cursor: default;
    // We disable the focus ring for mouse, touch and keyboard users.      outline: 0,
    text-decoration: none;
    border: 0; // Remove `button` border
    padding: 0; // Remove `button` padding
    vertical-align: middle;
    box-sizing: border-box;
    @each $key, $value in $colorMainMap {
        @at-root #{$_chip}-color-#{$key} {
            background-color: rgba(lighten($value, 10%), .2);
        }
    }
    @each $key, $value in $colorMainMap {
        @at-root #{$_chip}-color-#{$key} {
            color: $value;
            &:hover#{$_chip}-clickable {
                background-color: lighten($value, 25%);
            }
        }
    }
    @at-root #{$_chip}-no-shrink {
        flex-shrink: 0;
    }
    @at-root #{$_chip}-size-small {
        height: 2.4rem;
    }
    @at-root #{$_chip}-disabled {
        opacity: $actionDisabledOpacity;
        pointer-events: none;
    }
    @at-root #{$_chip}-clickable {
        user-select: none;
        -webkit-taphighlight-color: transparent;
        cursor: pointer;
        &:hover {
            background-color: rgba($textSecondaryColor, ($actionHoverOpacity + $activeSelectedOpacity));
        }
        &:active {
            box-shadow: map-get($shadows, 1);
        }
    }
    > #{$_chip}-avatar {
        margin-left: 0.5rem;
        margin-right: -0.6rem;
        width: 2.4rem;
        height: 2.4rem;
        color: gray(700);
        font-size: 1.2rem;
    }
    > #{$_chip}-avatar-small {
        margin-left: 4;
        margin-right: -4;
        width: 1.8rem;
        height: 1.8rem;
        font-size: 1rem;
    }
    > #{$_chip}-icon {
        color: gray(700);
        margin-left: 0.5rem;
        margin-right: -0.6rem;
    }
    > #{$_chip}-icon-size-small {
        color: gray(700);
        margin-left: 0.5rem;
        margin-right: -0.6rem;
        &[class*="-color-"] {
            color: inherit;
        }
    }
    > #{$_chip}-delete-icon {
        -webkit-tap-highlight-color: transparent;
        color: rgba($textPrimaryColor, 0.26);
        font-size: 2.2rem;
        cursor: pointer;
        margin: 0 0.5rem 0 -0.6rem;
        &:hover {
            color: rgba($textPrimaryColor, 0.66);
        }
    }
    > #{$_chip}-delete-icon-size-small {
        font-size: 1.6rem;
        margin-right: 0.4rem;
        margin-left: -0.4rem;
    }
    @each $key, $value in $colorMainMap {
        > #{$_chip}-delete-icon-color-#{$key} {
            color: rgba(255, 255, 255, 0.7);
            &:hover,
            &:active {
                color: rgba(255, 255, 255);
            }
        }
    }
}

#{$_chip}-variant-outlined {
    background-color: transparent;
    border: 1px solid gray(400);
    > #{$_chip}-avatar {
        margin-left: 0.4rem;
    }
    > #{$_chip}-avatar-small {
        margin-left: 0.2rem;
    }
    > #{$_chip}-icon {
        margin-left: 0.4rem;
    }
    > #{$_chip}-icon-size-small {
        margin-left: 0.2rem;
    }
    > #{$_chip}-delete-icon {
        margin-right: 0.5rem;
    }
    > #{$_chip}-delete-icon-size-small {
        margin-right: 0.3rem;
    }
    @each $key, $value in $colorMainMap {
        &#{$_chip}-color-#{$key} {
            color: $value;
            border: 1px solid rgba($value, 0.7);
            &:hover {
                border: 1px solid rgba($value, 0.7);
            }
        }
        > #{$_chip}-delete-icon-color-#{$key} {
            color: $value;
            // border: 1px solid rgba($value, 0.7);
            &:hover {
                color: 1px solid rgba($value, 0.7);
            }
        }
    }
    // clickable
    &#{$_chip}-clickable {
        &:hover {
            background-color: $actionHoverColor;
        }
        @each $key, $value in $colorMainMap {
            &#{$_chip}-color-#{$key} {
                color: $value;
                border: 1px solid rgba($value, 0.7);
                &:hover {
                    background-color: rgba($value, $actionHoverOpacity);
                }
            }
            > #{$_chip}-delete-icon-color-#{$key} {
                color: rgba($value, 0.7);
                &:hover,
                &:active {
                    color: $value;
                }
            }
        }
    }
}

#{$_chip}-label {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    white-space: nowrap;
    line-height: 3rem;
    @at-root #{&}-small {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
}
