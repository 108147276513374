.measurement-item {
  padding: 16px;
  &__title {
    font-size: 1.6rem;
    color: $gray500;
    padding: 0;
    margin-bottom: 8px;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }

  &__value {
    flex: 1 1;
    font-family: $fontFamilyByNumber;
    font-size: 1.4em;
    font-weight: bold;
  }

  &__value-unit {
    font-size: 0.5em;
    margin-left: 4px;
    color: $gray400;
  }

  & .measurement-bar {
    flex: 1 0 157px;
  }

  &.body-equilibrium-item {
    .measurement-item__value {
      flex: inherit;
      padding: 8px 10px;
      border-radius: $shapeBorderRadius;
      font-family: $fontFamily;
      font-weight: normal;
      font-size: 1.5rem;
      letter-spacing: 1px;
      &--normal {
        background-color: lighten($successColorLight, 25%);
        color: $successColorMain;
      }
      &--warning {
        background-color: lighten($errorColorLight, 25%);
        color: $errorColorMain;
      }
    }
  }

}


