$_card: ".card";
#{$_card}-root {
    overflow: hidden;

    @at-root #{$_card}-overflow-hidden {
        overflow: unset;
    }

    #{$_card}-header {
        display: flex;
        align-items: center;
        padding: 1.6rem 2rem;
    }

    #{$_card}-header #{$_card}-header-avatar {
        display: flex;
        flex: 0 0 auto;
        margin-right: 1rem;
    }

    #{$_card}-header #{$_card}-header-content {
        flex: 1 1 auto;
    }

    #{$_card}-header #{$_card}-header-content #{$_card}-header-content-title {
        color: $fontColor1;
        @include mxBuildVariant($fontWeightMedium, $H5FontSize, 1.5, 0.15);
        display: block;
        &.fullWidth {
            @include mxBuildVariant($fontWeightMedium, $H4FontSize, 1.334, 0);
        }
    }

    #{$_card}-header #{$_card}-header-content #{$_card}-header-content-subTitle {
        color: $fontColor2;
        @include mxBuildVariant($fontWeightRegular, $fontSize, 1.5, 0.15);
        display: block;
        margin-top: .8rem;
        &.fullWidth {
            @include mxBuildVariant($fontWeightRegular, 16, 1.34, 0.15);
        }
    }

    #{$_card}-header #{$_card}-header-action {
        flex: 0 0 auto;
        align-items: start;
        margin: -4px -8px -4px 0;
    }

    // #{$_card}-content {
    // padding: $cardPadding;
    // &:last-child {
    //     padding-bottom: $cardContentLastBottom;
    // }
    // }
    #{$_card}-actions {
        display: flex;
        align-items: center;
        padding: 8px;
        &.card-position-end {
            justify-content: flex-end;
        }
    }
    #{$_card}-actions#{$_card}-actions-spacing {
        & > :not(:first-of-type) {
            margin-left: 8px;
        }
    }
}
