$_popover: '.popover';

#{$_popover} {
    @at-root #{&}-paper {
        position: absolute;
        overflow-y: auto;
        overflow-x: overflow;
        min-width: 16px;
        min-height: 16px;
        max-width: calc(100% - 32px);
        max-height: calc(100% - 32px);
        outline: 0;
    }
    @at-root #{$_popover}-paper-border-radius {
        border-radius: 4px;
        .list {
            border-radius: 4px;
        }
    }
}
