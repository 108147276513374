$_menuItem: '.menu-item';
#{$_menuItem} {
    @include mxVariants();
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    min-height: 48px;
    padding: 6px 16px;
    box-sizing: border-box;
    white-space: nowrap;
    @at-root #{$_menuItem}-disable-gutters {
        padding-left: 0;
        padding-right: 0;
    }
    @at-root #{$_menuItem}-divider {
        border-bottom: 1px solid $dividerColor;
        background-clip: padding-box;
    }
    &:hover {
        text-decoration: none;
        background-color: rgba($actionHoverColor, 0.08);
        // Reset on touch devices, it doesn't add specificity
        @media (hover: none) {
            backgroundcolor: transparent;
        }
    }
    &#{$_menuItem}-selected {
        background-color: rgba($primaryColorMain, 0.4);
        em {
            color: $primaryColorLight;
        }
        &:hover {
            background-color: rgba($primaryColorMain, 0.32);
        }
    }
    &#{$_menuItem}-disabled {
        opacity: $actionDisabledOpacity;
    }
    @at-root #{$_menuItem}-dense {
        min-height: 36px;
        @include mxBuildVariant($fontWeightRegular, $body2Size, 1.43, 0.15);
        svg {
            font-size: 20px;
        }
    }
    .list-item-icon {
        min-width: 32px;
    }
    .list-item-text {
        flex: 1 1 auto;
    }
    em {
        color: gray(400);
    }
}
