.autocomplete-field {
    .MuiAutocomplete-root {
        .MuiAutocomplete-endAdornment {
            right: 7px;
            top: 50%;
            transform: translateY(-50%);
        }
        .MuiSvgIcon-root {
            font-size: 24px;
            color: #bdbdbd;
        }
    }
}
