.inbody-score-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: white;
  border-radius: $paperBorderRadius;
  box-shadow: map-get($shadows, 2);

  &__title {
    font-size: $H6FontSize;
    font-weight: bold;
    color: $fontColor2;
    margin: 0;
  }

  &__value {
    margin: 0;
  }

  &__score {
    font-family: $fontFamilyByNumber;
    font-size: 1.4em;
    font-weight: bold;
    color: $fontColor2;
  }
}

.inbody-score-box + .tip {
  margin-top: 8px;
  color: $fontColor3;
}