.dialog-title-header {
  &.MuiDialogTitle-root {
    position: relative;
    padding-right: 60px;
    border-bottom: 1px solid $gray200;
  }

  &__text {
    font-size: 1.7rem;
    color: $fontColor1;
  }

  &__close {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 0;
  }
}