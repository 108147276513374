.cid-box {
  padding: 0 16px;
}

.cid-box-item {
  display: flex;
  align-items: center;
  gap: 8px;

  &__title {
    flex: 1 1;
    font-size: $body1Size;
    color: $gray500;
  }

  & .measurement-bar {
    flex: 1 0 157px;
    margin-bottom: 0;
  }
}
