
.switch-base-button {
    padding: 9px;
    border-radius: 50%;
    &#{&}-edge-start {
        margin-left: -12px;
        &.switch-base-button-size-small {
            margin-left: -3px;
        }
    }
    &#{&}-edge-end {
        margin-right: -12px;
        &.switch-base-button-size-small {
            margin-right: -3px;
        }
    }
}

.switch-base-input {
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 1;
}
