.typography-body2 {
    font-size: $body2Size;
    line-height: $lineHeight;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.line-height-1 {
    line-height: 1 !important;
}

@for $i from 1 through 5 {
    .text-ellipsis-columns-#{$i} {
        display: -webkit-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: #{$i};
    }
}

.white-space-normal {
    white-space: normal !important;
}

.white-space-pre-line {
    white-space: pre-line !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.word-break-break-word {
    word-break: break-word !important;
}

.vertical-align-middle {
    vertical-align: middle !important;
}
