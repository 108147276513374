$primaryColorDarken: #6e2f8e;
$primaryColorMain: #a45bc8;
$primaryColorLight: #f0e4f6;
$secondaryColorDarken: #3c2373;
$secondaryColorMain: #653ac2;
$secondaryColorLight:#e5ddf5;
$warningColorDarken: #995719;
$warningColorMain: #ff912a;
$warningColorLight: #ffa552;
$errorColorDarken: #d32f2f;
$errorColorMain: #f44336;
$errorColorLight: #e57373;
$successColorDarken: #388e3c;
$successColorMain: #66bb6a;
$successColorLight: #81c784;
$infoColorDarken: #4fc3f7;
$infoColorMain: #29b6f6;
$infoColorLight: #0288d1;

/* Gray */
$gray50: #f0f0f0;
$gray100: #e3e3e3;
$gray200: #cccccc;
$gray300: #bbbbbb;
$gray400: #a3a3a3;
$gray500: #8c8c8c;
$gray600: #5d5d5d;
$gray700: #464646;
$gray800: #2e2e2e;
$gray900: #1c1c1c;

$markColor: #ffee58;
$primaryGradientMain: linear-gradient(to right, $secondaryColorMain, $primaryColorMain);

/* background */
$backgroundDefault: #f0f0f0;
$backgroundPaper: #fff;
$backgroundPage: #f0f4f8;

$backgroundLight: #f1f1f1;
$backgroundLightActive: #c1c1c1;

/* font color */
$fontColor1: #0c0e19;
$fontColor2: #424654;
$fontColor3: #676a92;
$fontColor4: #aaaebe;

/* Text Color */
$textPrimaryColor: #0c0e19;
$textSecondaryColor: #424654;
$textDisabledColor: #aaaebe;
$textInputColor: #0c0e19;

/* Title Color */
$titleColor: $textPrimaryColor;

/* divider */
$dividerColor: rgba(0, 0, 0, 0.2);

/* ShowLoading */
$showLoadingBackgroundColor: rgba(0, 0, 0, 0.48);

$htmlFontSize: 12px; // ***** impotant ,web body base size *****

/* Font Size and typography */
$H1FontSize: 4rem;
$H2FontSize: 3.8rem;
$H3FontSize: 2.8rem;
$H4FontSize: 2.4rem;
$H5FontSize: 2.2rem;
$H6FontSize: 1.8rem;
$subtitle1Size: 1.7rem;
$subtitle2Size: 1.6rem;
$body1Size: 1.7rem;
$body2Size: 1.6rem;
$captionSize: 1.2rem;
$buttonFontSize: 1.7rem;

$fontSize: 1.7rem;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

/* Font weight & Font family */
$fontFamily: 'Noto Sans TC', sans-serif;
$fontFamilyByNumber: "SanFranciscoText-Bold", "Helvetica Neue", "Helvetica", "Arial", sans-serif;;

$titleLineHeight: 1.2;
$lineHeight: 1.5;

$fontBackground: rgba(0, 0, 0, 0.48);
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

$borderColor: rgba(255, 255, 255, 0.24);
$borderColor2: rgba(255, 255, 255, 0.14);

/* action */
// The colors used to style the action elements.
$actionActiveColor: rgba(0, 0, 0, 0.54); // The color of an active action like an icon button.
$actionHoverColor: rgba(0, 0, 0, 0.06); // The color of an hovered action.
$actionHoverOpacity: 0.04;
$activeSelectedColor: rgba(0, 0, 0, 0.08);
$activeSelectedOpacity: 0.08;
$actionDisabledColor: rgba(0, 0, 0, 0.26); // The color of a disabled action.
$actionDisabledBackground: rgba(0, 0, 0, 0.05); // The background color of a disabled action.
$actionDisabledOpacity: 0.45;
$actionFocusColor: rgba(0, 0, 0, 0.12);
$actionFocusOpacity: 0.12;
$actionActivatedOpacity: 0.12;

/*-------------------- Animated Start --------------------*/
// ease
$easeInOut: cubic-bezier(0.4, 0, 0.2, 1);
$easeOut: cubic-bezier(0, 0, 0.2, 1);
$easeIn: cubic-bezier(0.4, 0, 1, 1);
$sharp: cubic-bezier(0.4, 0, 0.6, 1);

// duration
$durationShortest: 150;
$durationShorter: 200;
$durationShort: 250;
$durationStandard: 300;
$durationComplex: 375;
$durationEnteringScreen: 225;
$durationLeavingScreen: 195;
/*-------------------- Animated End --------------------*/

/*-------------------- Zoom Start --------------------*/
// z-index
$mobileStepperZIndex: 1000;
$speedDialZIndex: 1050;
$appBarZIndex: 1100;
$drawerZIndex: 1200;
$modalZIndex: 1300;
$snackbarZIndex: 1400;
$tooltipZIndex: 1500;
$showloadingZIndex: 2000;
/*-------------------- Zoom End --------------------*/

/* Papers */
$paperBorderRadius: 1rem;

/* Shape */
$shapeBorderRadius: 0.4rem;

/* Button BorderRadius */
$buttonBorderRadius: 0.8rem;

/* Card */
$cardPadding: 2rem;
$cardContentLastBottom: 2.4rem;

$navagationWidth: 250px;
$snackbarFontSize: 1.6rem;

$mobileStepperZIndex: 1000;
$speedDialZIndex: 1050;
$appBarZIndex: 1100;
$drawerZIndex: 1200;
$modalZIndex: 1300;
$snackbarZIndex: 1400;
$tooltipZIndex: 1500;
$showloadingZIndex: 2000;

$blueGray100: #cfd8dc;
$blueGray200: #b0bec5;

$fontColors: $fontColor1, $fontColor2, $fontColor3, $fontColor4;

/* Grid Spacing */
$gridSpacing: (0.8rem, 1.6rem, 2.4rem, 3.2rem, 4.8rem);

$spacer: 0.8rem;
$spacers: (
    0: 0,
    1: $spacer * 0.5,
    2: $spacer * 2,
    3: $spacer * 2.5,
    4: $spacer * 3,
    5: $spacer * 5,
);


$mobile-container-width: 430px;


// xs: 0, // phone
// sm: 600, // tablets
// md: 900, // small laptop
// lg: 1200, // desktop
// xl: 1536, // large screens  不參考
@import './variableMap';
