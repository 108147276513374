$_formControl: '.form-control';
#{$_formControl}-root {
    position: relative;
    display: inline-flex;
    // Reset fieldset default style.
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    vertical-align: top; // Fix alignment issue on Safari.
    &#{$_formControl}-direction-column {
        flex-direction: column;
    }
    &#{$_formControl}-direction-row {
        flex-direction: row;
        > label {
            padding: 8px 12px 0 0;
        }
    }
    @at-root #{$_formControl}-margin-normal {
        margin-top: 16px;
        margin-bottom: 8px;
    }
    @at-root #{$_formControl}-margin-dense {
        margin-top: 8px;
        margin-bottom: 4px;
    }
    @at-root #{$_formControl}-full-width {
        width: 100%;
        .formlabel {
            white-space: nowrap;
        }
        .field-input-root {
            width: 100%;
        }
    }
}
