.dialog-title-header-primary {
    position: relative;
    background: linear-gradient(to right, $primaryColorMain, $secondaryColorMain);
    color: gray(50);
    > .dialog-title-header-close-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
    }
    > .dialog-title-margin {
        margin: 0 2rem;
    }
}

.dialog-dotted-line {
    @include mxFakeDashed();
    margin: 12px 0;
}
