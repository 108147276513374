$_switch: '.switch';
$_switchBase: $_switch + '-base';
$_switchTrack: $_switch + '-track';
$_switchThumb: $_switch + '-thumb';

#{$_switch} {
    display: inline-flex;
    width: (34 + (12 * 2)) + px;
    height: (14 + (12 * 2)) + px;
    overflow: hidden;
    padding: 12px;
    box-sizing: border-box;
    position: relative;
    flex-shrink: 0;
    z-index: 0; // Reset the stacking context.
    vertical-align: middle; // For correct alignment with the text.
    @media print {
        color-adjust: exact;
    }
    @at-root #{$_switch}-edge-start {
        margin-left: -8px;
    }
    @at-root #{$_switch}-edge-end {
        margin-right: -8px;
    }
    @at-root #{$_switch}-size-small {
        width: 40px;
        height: 24px;
        padding: 7px;
        #{$_switchThumb} {
            width: 16px;
            height: 16px;
        }
        #{$_switchBase} {
            padding: 4px;
            &#{$_switchBase}-checked {
                transform: translateX(16px);
            }
        }
    }
    + .form-control-label-text {
        margin-top: 0;
        align-self: center;
    }
}

#{$_switchBase} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: gray(500);
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &#{$_switchBase}-input {
        left: -100%;
        width: 300%;
    }
    &#{$_switchBase}-checked {
        transform: translateX(20px);
        @each $key, $value in $colorMainMap {
            &#{$_switchBase}-color-#{$key} {
                color: $value;
            }
        }
    }
    &:hover {
        background-color: rgba($actionActiveColor, $actionHoverOpacity);
        @media (hover: none) {
            background-color: transparent;
        }
        @each $key, $value in $colorMainMap {
            &#{$_switchBase}-color-#{$key} {
                background-color: rgba($value, $actionHoverOpacity);
            }
        }
    }
    @at-root #{$_switchBase}-checked + #{$_switchTrack} {
        opacity: 0.5;
        @each $key, $value in $colorMainMap {
            &#{$_switchTrack}-color-#{$key} {
                background-color: $value;
            }
        }
    }
    @at-root #{$_switchBase}-disabled + #{$_switchTrack} {
        opacity: 0.12;
    }
    @at-root #{$_switchBase}-disabled {
        color: gray(100);
        @each $key, $value in $colorMainMap {
            &#{$_switchBase}-color-#{$key} {
                color: lighten($value, 20%);
            }
        }
    }
}

#{$_switchTrack} {
    height: 100%;
    width: 100%;
    border-radius: calc(14 / 2) + px;
    z-index: -1;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: gray(500);
    opacity: 0.38;
}

#{$_switchThumb} {
    box-shadow: map-get($shadows, 1);
    background-color: currentColor;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
