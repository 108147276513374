.outlinedinput-root {
    position: relative;
    border-radius: $shapeBorderRadius;
    &:hover {
        .outlinedinput-notchedOutline {
            border-color: $textSecondaryColor;
        }
    }
    // Reset on touch devices, it doesn't add specificity
    @media (hover: none) {
        &:hover .outlinedinput-notchedOutline {
            border-color: $textSecondaryColor;
        }
    }
    &.outlinedinput-root-focused {
        .outlinedinput-notchedOutline {
            border-color: $textPrimaryColor;
            border-width: 2px;
        }
    }
    &.outlinedinput-root-error {
        .outlinedinput-notchedOutline {
            border-color: $errorColorMain;
            background-color: rgba($errorColorMain, 0.06);
        }
    }
    &.outlinedinput-root-disabled {
        .outlinedinput-notchedOutline {
            border-color: $actionDisabledColor;
        }
    }
    &.outlinedinput-root-startAdornment {
        padding-left: 14px;
    }
    &.outlinedinput-root-endAdornment {
        padding-right: 14px;
    }
    &.outlinedinput-root-multiline {
        padding: 8.5px 14px;
        &.outlinedinput-root-size-large {
            padding: 16.5px 14px;
        }
    }
}

.outlinedinput-input {
    padding: 8.5px 14px;
    &:-webkit-autofill {
        -webkit-box-shadow: null;
        box-shadow: null;
        -webkit-text-fill-color: null;
        caret-color: null;
        border-radius: inherit;
    }
    &#{&}-size-large {
        padding: 16.5px 14px;
    }
    &#{&}-startAdornment {
        padding-left: 0;
    }
    &#{&}-endAdornment {
        padding-right: 0;
    }
}

fieldset.outlinedinput-notchedOutline {
    border-color: $borderColor;
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
}

.outlinedinput-notchedOutline > legend {
    float: unset;
    padding: 0;
    line-height: 11px;
    white-space: nowrap;
    transition: width 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    &.outlinedinput-notchedOutline-label {
        display: block;
        width: auto;
        padding: 0;
        height: 11px;
        line-height: normal;
        font-size: 0.75em;
        visibility: hidden;
        max-width: 0.01px;
        transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        & > span {
            padding-left: 5px;
            padding-right: 5px;
            display: inline-block;
        }
        &.outlinedinput-notchedOutline-notched {
            max-width: 100%;
            transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
        }
    }
}
