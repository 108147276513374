.count-controller {
    .count {
        width: 7rem;
        padding: 8px 15px;
        text-align: center;
    }
    .icon-button {
       --size: 14px;
       --lineWeight: 3px;
       &.minus,
       &.plus {
           >.icon {
               display: block;
               width: var(--size);
               height: var(--size);
               position: relative;
               &::before {
                   content: "";
                   position: absolute;
                   top: 50%;
                   left: 50%;
                   width: 100%;
                   height: var(--lineWeight);
                   background-color: white;
                   transform: translate(-50%, -50%);
               }
           }

       }
       &.minus {
           background-color: #4caf50;
       }
       &.plus {
           background-color: #f44336;
           > .icon {
               &::after {
                   content: "";
                   position: absolute;
                   top: 50%;
                   left: 50%;
                   width: 3px;
                   height: 100%;
                   background-color: white;
                   transform: translate(-50%, -50%);
               }
           }
       }
   }
}
