.table-container {
    width: 100%;
    overflow: auto;
    // overscroll-behavior: contain;   //阻止外層滾動
    &#{&}-overflow-unset {
        overflow: unset;
    }
}

.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    overscroll-behavior: contain;
    &#{&}-sticky-header {
        border-collapse: separate;
    }
}

.table-head {
    display: table-header-group;
}

.table-body {
    display: table-row-group;
}

.table-footer {
    display: table-footer-group;
}

.table-row {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0;
    &#{&}-hover {
        &:hover {
            background-color: rgba($infoColorMain, $actionHoverOpacity);
            .table-cell-fixed {
                background-color: $backgroundDefault;
            }
        }
    }
    &#{&}-selected {
        background-color: rgba($infoColorMain, $activeSelectedOpacity);
        &:hover {
            background-color: rgba($infoColorMain, $activeSelectedOpacity + $actionHoverOpacity);
        }
    }
}

.table-cell {
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid $borderColor;
    text-align: left;
    padding: 1.2rem;
    line-height: $lineHeight;
    &#{&}-head {
        color: $textPrimaryColor;
        font-weight: $fontWeightMedium;
    }
    &#{&}-body {
        color: $textPrimaryColor;
    }
    &#{&}-footer {
        color: $textSecondaryColor;
        line-height: 2.1;
        font-size: 1.2rem;
    }
    &#{&}-size-small {
        padding: 6px 16px;
        &.table-cell-padding-checkbox {
            width: 24px; // prevent the checkbox column from growing
            padding: 0 12px 0 16px;
            & > * {
                padding: 0;
            }
        }
    }
    &#{&}-white-space-pre {
        white-space: pre;
    }
    &#{&}-white-space-nowrap {
        white-space: nowrap;
    }
    &#{&}-padding-checkbox {
        width: 48px;
        padding: 0 0 0 4px;
    }
    &#{&}-padding-none {
        padding: 0;
    }
    &#{&}-align-left {
        text-align: left;
    }
    &#{&}-align-center {
        text-align: center;
    }
    &#{&}-align-right {
        text-align: right;
    }
    &#{&}-align-justify {
        text-align: justify;
    }
    &#{&}-sticky-header {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $backgroundPaper;
    }
}

.theme-gray {

    .table-head {
        > tr {
            th,
            td {
                background-color: $backgroundDefault;
            }
            > :first-child {
                border-top-left-radius: $shapeBorderRadius;
            }
            > :last-child {
                border-top-right-radius: $shapeBorderRadius;
            }
        }
    }
}

.table-fixed {
    table-layout: fixed;
    width: 100%;
    tbody {
        .table-cell-fixed {
            white-space: normal;
        }
    }
}

.table-head-fixed {
    position: sticky;
    top: 0;
    z-index: $speedDialZIndex;
    background: $backgroundDefault;
    box-shadow: map-get($shadows, 2);
}

.table-cell-fixed {
    position: sticky;
    z-index: $mobileStepperZIndex;
    background-color: $backgroundDefault;
    box-shadow: map-get($shadows, 3);
    &-left ~ &-left {
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            @include mxFakeDashed($gray400, 6px, 'col');
        }
    }
    &-right ~ &-right {
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            @include mxFakeDashed($gray400, 6px, 'col');
        }
    }
}

.overview {
    .overview-item-invalid {
        opacity: .5;
    }
    a {
        color: $secondaryColorMain;
        text-decoration: underline;
    }
}
