.pos-absolute {
    position: absolute !important;
}

.pos-relative {
    position: relative !important;
}

.pos-fixed {
    position: fixed !important;
}

.pos-sticky {
    position: sticky !important;
}
