$_buttonGroup: ".button-group";
#{$_buttonGroup}-root {
    display: inline-flex;
    border-radius: $buttonBorderRadius;
    &#{$_buttonGroup}-variant-contained {
        box-shadow: map-get($shadows, 2);
    }
    &#{$_buttonGroup}-disable-elevation {
        box-shadow: none;
    }
    &#{$_buttonGroup}-full-width {
        width: 100%;
    }
    &#{$_buttonGroup}-disabled {
        cursor: not-allowed;
    }
    &#{$_buttonGroup}-orientation-vertical {
        flex-direction: column;
    }
    #{$_buttonGroup}-grouped {
        min-width: 4rem;
        &#{$_buttonGroup}-full-width {
            width: 100%;
        }
        &:not(:first-of-type) {
            &#{$_buttonGroup}-orientation-horizontal {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                &#{$_buttonGroup}-variant-outlined {
                    margin-left: -0.1rem;
                }
            }
            &#{$_buttonGroup}-orientation-vertical {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                &#{$_buttonGroup}-variant-outlined {
                    margin-top: -0.1rem;
                }
            }
        }
        &:not(:last-of-type) {
            &#{$_buttonGroup}-orientation-horizontal {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                &#{$_buttonGroup}-variant-text {
                    border-right: 1px solid rgba(0, 0, 0, 0.23);
                }
                &#{$_buttonGroup}-variant-outlined {
                    border-right-color: transparent;
                }
                &#{$_buttonGroup}-variant-contained {
                    border-right: 1px solid gray(400);
                    &#{$_buttonGroup}-disabled {
                        border-right: 1px solid $actionDisabledColor;
                    }
                }
            }
            &#{$_buttonGroup}-orientation-vertical {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                &#{$_buttonGroup}-variant-text {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
                }
                &#{$_buttonGroup}-variant-outlined {
                    border-bottom-color: transparent;
                }
                &#{$_buttonGroup}-variant-contained {
                    border-bottom: 1px solid gray(400);
                    &#{$_buttonGroup}-disabled {
                        border-bottom: 1px solid $actionDisabledColor;
                    }
                }
            }
            &#{$_buttonGroup}-variant-text {
                @each $key, $value in $colorMainMap {
                    &#{$_buttonGroup}-color-#{$key} {
                        border-color: rgba($value, 0.5);
                    }
                }
            }
        }
        &:hover {
            &#{$_buttonGroup}-variant-outlined {
                @each $key, $value in $colorMainMap {
                    &#{$_buttonGroup}-color-#{$key} {
                        border-color: darken($value, 10%);
                        z-index: 1;
                    }
                }
            }
            &#{$_buttonGroup}-variant-contained {
                box-shadow: none;
            }
        }
        &#{$_buttonGroup}-variant-contained {
            box-shadow: none;
        }
    }
}

.btn-group {
    &:not(&.no-gutter){
        > *:not(:last-child) {
            margin-right: 1.6rem;
        }
    }
}
