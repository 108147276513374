.paper {
    background-color: $backgroundPaper;
    border-radius: $paperBorderRadius;
    // overflow: hidden;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &#{&}-outlined {
        border: 1px solid $dividerColor;
    }
    &#{&}-square {
        border-radius: unset;
    }
}
