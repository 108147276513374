$_formControlLabel: '.form-control-label';
#{$_formControlLabel}-root {
    user-select: none;
    display: inline-flex;
    align-items: flex-start;
    cursor: pointer;
    // For correct alignment with the text.
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-left: -11px;
    margin-right: 16px; // used for row presentation of radio/checkbox
    @at-root #{$_formControlLabel}-label#{$_formControlLabel}-disabled {
        color: $textDisabledColor;
    }
    @at-root #{$_formControlLabel}-disabled {
        cursor: default;
    }
    @at-root #{$_formControlLabel}-label-placement-start {
        flex-direction: row-reverse;
        margin-left: 16px; // used for row presentation of radio/checkbox
        margin-right: -11px;
    }
    @at-root #{$_formControlLabel}-label-placement-top {
        flex-direction: column-reverse;
        margin-left: 16px;
    }
    @at-root #{$_formControlLabel}-label-placement-bottom {
        flex-direction: column;
        margin-left: 16px;
    }
}

#{$_formControlLabel} {
    &-text {
        margin-top: 8px;
    }
}
