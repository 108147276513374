$_avatarGroup: ".avatar-group";

#{$_avatarGroup}-root {
    display: flex;
    flex-direction: row-reverse;
    #{$_avatarGroup}-sub {
        border: 2px solid rgb(255, 255, 255);
        box-sizing: content-box;
        margin-left: -8px;
        border-radius: 999999px;
        background-color: #fff;
        &:last-child {
            margin-left: 0;
        }
    }
}
