.ban-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cursor-pointer {
    cursor: pointer !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.visibility-hidden {
    visibility: hidden !important;
}

/*
    文字標記
 */
.mark {
    letter-spacing: 0.12rem;
    font-weight: $fontWeightMedium;
    display: inline-block;
    padding: 0.2rem 0.8rem;
    margin: 0.8rem;
    position: relative;
    z-index: 10;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: $markColor;
        z-index: -1;
    }
}

.sms-points {
    background: darken($primaryColorMain, 10);
    color: $primaryColorDarken;
    border-radius: 6px;
    letter-spacing: 0.3px;
}
