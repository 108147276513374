.date-range-picker {
    flex-wrap: nowrap;
    &-joint {
        line-height: 4rem;
        padding: 0 2rem;
    }
}

// overide mui DatePicker
.mobile {
    .MuiCalendarPicker-root {
        .MuiPickersCalendarHeader-label {
            font-size: 2rem;
        }

        .MuiIconButton-root .MuiSvgIcon-root {
            font-size: 2.6rem;
        }

        .MuiPickersDay-root {
            font-size: 2rem;
        }

        .PrivatePickersYear-yearButton {
            font-size: 2rem;
        }

        .PrivatePickersMonth-root {
            font-size: 2rem;
        }

        .MuiDayPicker-weekDayLabel {
            font-size: 2rem;
        }

        .MuiPickersArrowSwitcher-button svg {
            font-size: 2rem;
        }
    }
    .mobile-date-picker-action .MuiButton-text {
        font-size: 2rem;
    }
}

.MuiCalendarPicker-root {
    .MuiPickersCalendarHeader-label {
        font-size: 1.6rem;
    }
    .MuiDayPicker-weekDayLabel {
        font-size: 1.6rem;
    }
    .MuiPickersDay-root {
        font-size: 1.4rem;
    }
    .PrivatePickersYear-yearButton {
        font-size: 1.4rem;
    }
    .PrivatePickersMonth-root {
        font-size: 1.4rem;
    }
    .MuiMonthPicker-root .PrivatePickersMonth-root:disabled {
        color: rgba(0, 0, 0, 0.3);
    }
}

.MuiDialogActions-root.mobile-date-picker-action .MuiButton-root {
    font-size: 1.6rem;
}

