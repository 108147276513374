$_formGroup: ".form-group";
#{$_formGroup}-root {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    @at-root #{$_formGroup}-gap {
        row-gap: 1rem; // 10px
    }
    @at-root #{$_formGroup}-flex-direction-row {
        flex-direction: row;
        &.form-group-gap {
            column-gap: 0.5rem; // 5px
        }
    }
    @at-root #{$_formGroup}-flex-origin-end {
        justify-content: flex-end;
    }
}
