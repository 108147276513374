// allen todo
.modal-protal {
    position: relative;
    z-index: $showloadingZIndex;
}

.modal {
    position: fixed;
    z-index: $modalZIndex;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    .backdrop {
        z-index: -1;
    }
    &-overlay {
        position: fixed;
        inset: 0px;
        background-color: $showLoadingBackgroundColor;
        z-index: $showloadingZIndex;
    }
    &-content {
        position: absolute;
        overflow: auto;
        outline: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
