.checkbox {
    color: $primaryColorMain;
    &:not(.checkbox-disabled-ripple):hover {
        background-color: rgba($actionActiveColor, 0.04);
        @each $key, $value in $colorMainMap {
            .checkbox-color-#{$key} {
                background-color: rgba($value, $actionHoverOpacity);
            }
        }
    }
    @media (hover: none) {
        background-color: transparent;
    }

    &#{&}-disabled {
        color: $actionDisabledColor;
    }
}
