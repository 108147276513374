.calendar {
    display: table-cell;
    width: 280px;
    #{&}-container {
        padding: 6px 0;
    }
    #{&}-title {
        text-align: center;
        font-size: 1.6rem;
        font-weight: $fontWeightMedium;
        padding: 20px 0;
    }
    #{&}-week {
        display: table;
        width: 100%;
        &-header {
            color: gray(500);
            line-height: 32px;
        }
    }
    #{&}-day {
        display: table-cell;
        border-radius: 50%;
        width: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
    }
    #{&}-today {
        color: $errorColorMain !important;
        font-weight: $fontWeightMedium;
    }
    #{&}-disabled {
        color: gray(300);
    }
    #{&}-between {
        color: #fff;
        background-color: $primaryColorLight;
    }
    #{&}-selected {
        color: gray(200);
        background-color: $primaryColorMain;
        border-radius: 20px;
    }

    #{&}-selected-start {
        @extend .calendar-selected;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    #{&}-selected-end {
        @extend .calendar-selected;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    #{&}-others {
        color: gray(300);
        background-color: transparent;
    }
}

.calendar {
    #{&}-container #{&}-day#{&}-hover {
        &:hover {
            transition: all 0.2s linear;
            color: #fff !important;
            background-color: $primaryColorDarken !important;
        }
    }
    #{&}-container #{&}-today#{&}-hover {
        &:hover {
            transition: all 0.2s linear;
            color: #fff !important;
            background-color: $primaryColorDarken !important;
            border: unset;
        }
    }
}
