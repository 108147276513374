$_snackbar: '.snackbar';
$_snackbarContent: '.snackbar-content';
$_snackbarItem: '.snackbar-item';

#{$_snackbar}-root {
    z-index: $snackbarZIndex;
    position: fixed;
    display: flex;
    left: 8px;
    right: 8px;
    justify-content: center;
    align-items: center;
    @at-root #{$_snackbar}-vertical-top {
        top: 8px;
    }
    @at-root #{$_snackbar}-vertical-bottom {
        bottom: 8px;
    }
    @at-root #{$_snackbar}-horizontal-left {
        justify-content: flex-start;
    }
    @at-root #{$_snackbar}-horizontal-right {
        justify-content: flex-end;
    }
    @media (min-width: 600px) {
        @at-root #{$_snackbar}-vertical-top {
            top: 24px;
        }
        @at-root #{$_snackbar}-vertical-bottom {
            bottom: 24px;
        }
        @at-root #{$_snackbar}-horizontal-center {
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }
        @at-root #{$_snackbar}-horizontal-left {
            left: 24px;
            right: auto;
        }
        @at-root #{$_snackbar}-horizontal-right {
            right: 24px;
            left: auto;
        }
    }
}

#{$_snackbarContent}-root {
    @include mxVariants('body2');
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.6rem 1.6rem;
    border-radius: $shapeBorderRadius;
    flex-grow: 1;
    // sm
    @media (min-width: 600px) {
        flex-grow: initial;
        min-width: 28.8rem;
    }
    @each $key, $value in $colorMainMap {
        @at-root #{$_snackbarContent}-color-#{$key} {
            background-color: $value;
            color: rgb(255, 255, 255);
        }
    }
}

#{$_snackbarContent}-message {
    padding: 0.8rem 0;
}

#{$_snackbarContent}-action {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 1.6rem;
    margin-right: -0.8rem;
}

// contentRoot: `${componentName}-content-root`,
// lessPadding: `${componentName}-less-padding`,
// variantSuccess: `${componentName}-variant-success`,
// variantError: `${componentName}-variant-error`,
// variantInfo: `${componentName}-variant-info`,
// variantWarning: `${componentName}-variant-warning`,
// message: `${componentName}-message`,
// action: `${componentName}-action`,
// wrappedRoot: `${componentName}-wrapped-root`,

#{$_snackbarItem}-content-root {
}
#{$_snackbarItem}-wrapped-root {
}
#{$_snackbarItem}-less-padding {
}
#{$_snackbarItem}-variant-success {
}
#{$_snackbarItem}-variant-error {
}
#{$_snackbarItem}-variant-info {
}
#{$_snackbarItem}-variant-warning {
}
#{$_snackbarItem}-message {
}
#{$_snackbarItem}-action {
}
