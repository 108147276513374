.part-analysis-section {
  &__controller {
    padding: 4px;
    display: flex;
    gap: 4px;
    border-radius: $buttonBorderRadius;
    background: $gray100;
    margin-bottom: 14px;

    > button {
      flex: 1 1 50%;
      padding: 8px 20px;
      border-width: 0;
      border-radius: $buttonBorderRadius;
      font-size: 1.5rem;
      font-weight: 500;
      color: $fontColor2;
      background: transparent;
      opacity: .5;
      &.active {
        background: white;
        opacity: 1;
      }
    }
  }
}

